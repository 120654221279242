<template>
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.9207 8.39941H11.6907H6.08072C5.12072 8.39941 4.64073 9.55941 5.32073 10.2394L10.5007 15.4194C11.3307 16.2494 12.6807 16.2494 13.5107 15.4194L15.4807 13.4494L18.6907 10.2394C19.3607 9.55941 18.8807 8.39941 17.9207 8.39941Z"
      fill="#101840"
    />
  </svg>
</template>
