import { UserType } from "@/apps/authentication/types/AuthTypes";

import { defineStore } from "pinia";

export const useStore = defineStore({
  id: "login_data",
  state: () => ({
    email: "",
    id: 0,
    manager: 0,
    adviser: [] as Array<number>,
    url: "",
    userName: "",
    allUsers: [] as Array<object>,
    user: {} as UserType,
    profilePicture: "",
    empInfoUsers: [] as Array<object>,
    permissions: {},
    currentStep: null,
    totalStep: null,
    onboardingPending: false as boolean,
    userInfo: {} as UserType,
    cdcAdvisor: "" as string | number,
    loanuser: 0,
    showAll: true,
    loginHint: ""
  }),
  getters: {},
  actions: {
    setUser(data: UserType) {
      this.user = data;
    },

    setLoginHint(data: string) {
      this.loginHint = data;
    }
  },
  persist: true
});

export const useConfig = defineStore({
  id: "config",
  state: () => ({
    departments: [] as Array<object>,
    positions: [] as Array<object>
  }),
  getters: {},
  actions: {},
  persist: true
});

export const userInfo = defineStore({
  id: "user_info",
  state: () => ({
    userInfo: {} as object,
    empInfo: [] as Array<object>,
    empStatus: [] as Array<object>
  }),
  getters: {},
  actions: {
    setUser(data: object) {
      this.userInfo = data;
    }
  },
  persist: false
});

export const useSideBar = defineStore({
  id: "sidebar",
  state: () => ({
    showAll: false,
    toggleFull_Screen: false
  }),
  persist: true
});

export const useAlertSystem = defineStore({
  id: "alert",
  state: () => ({
    message: "",
    open: false,
    severity: "info" as "error" | "success" | "info"
  }),
  actions: {
    setAlert(alert: [string, "info" | "error" | "success"]) {
      this.open = true;
      this.message = alert[0].length > 100 ? "Contact for support" : alert[0];
      this.severity = alert[1];
    }
  }
});
