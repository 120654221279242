<template>
  <hr :class="dividerClasses" />
</template>

<script setup lang="ts">
import { computed } from "vue";

const props = defineProps({
  height: {
    type: String,
    default: "h-0.2"
  },
  marginTop: {
    type: String,
    default: "my-4"
  },
  backgroundColor: {
    type: String,
    default: "bg-gray-300"
  },
 
  darkBackgroundColor: {
    type: String,
    default: "dark:bg-gray-300"
  }
});

const dividerClasses = computed(() => [
  props.height,
  props.marginTop,
  props.backgroundColor,
  props.darkBackgroundColor
]);
</script>
