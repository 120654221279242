<template>
    <div>
        <div class="m-5">
            <div class="">
                <img src="../assets/MobileView.gif" class="rounded-full" alt="">
            </div>
            <h1 class="text-[#101840] font-bold">Sorry!!!</h1>
            <h3 class="text-[#101840]">This page is unavailable on Mobile</h3>
            <p class="N600">
                For optimum result please continue on PC. <br />
            </p>
        </div>
    </div>
</template>


<style scoped>
.gif-img {
    width: 100%;
    height: 240px;
    background-image: url('../assets/MobileView.gif');
    background-size: cover;
}

.mobile-messege-notice {
    display: flex;
    justify-content: center;
    align-items: baseline;
}
</style>