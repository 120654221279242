<template>
    <div class="cursor-pointer" @click="$emit('help')">
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="40" height="40" rx="8" fill="#D8DAE5" />
        <rect x="8.75" y="8.75" width="22.5" height="22.5" rx="11.25" stroke="#101840" stroke-width="1.5" />
        <path
            d="M20.0688 22.35C19.5198 22.35 19.0646 21.8948 19.0646 21.3458V21.0646C19.0646 19.5113 20.2027 18.7481 20.6312 18.4535C21.1267 18.1187 21.2873 17.8911 21.2873 17.5429C21.2873 16.8734 20.7384 16.3244 20.0688 16.3244C19.3993 16.3244 18.8504 16.8734 18.8504 17.5429C18.8504 18.0919 18.3951 18.5472 17.8461 18.5472C17.2971 18.5472 16.8418 18.0919 16.8418 17.5429C16.8418 15.762 18.2879 14.3158 20.0688 14.3158C21.8498 14.3158 23.2959 15.762 23.2959 17.5429C23.2959 19.0694 22.1711 19.8327 21.756 20.1139C21.2338 20.462 21.0731 20.6897 21.0731 21.0646V21.3458C21.0731 21.9082 20.6178 22.35 20.0688 22.35Z"
            fill="#101840" />
        <path
            d="M20.0707 25.6842C19.5083 25.6842 19.0664 25.2289 19.0664 24.6799C19.0664 24.1309 19.5217 23.6757 20.0707 23.6757C20.6197 23.6757 21.075 24.1309 21.075 24.6799C21.075 25.2289 20.6331 25.6842 20.0707 25.6842Z"
            fill="#101840" />
    </svg>
    </div>

</template>