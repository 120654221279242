<template>
  <div>
    <RouterLink :to="(item?.route as string)" class="">
      <div class="
          flex
          items-center
          gap-4
          rounded-lg
          mr-4
          ml-3
          cursor-pointer

          py-3
          px-4
        " :class="($route.path === item?.route) && sideBar.showAll
          ? 'bg-[#fff0ea] my-2'
          : sideBar.showAll || showLabel
            ? 'hover:bg-[#f2f2f2] hover:text-[#696F8C] my-2'
            : !sideBar.showAll && $route.path === item?.route  ? 'bg-[transparent] mr-10 my-4' : 'my-4'
          ">
        <TooltipComponent :text="item?.label" v-if="!sideBar.showAll && !showLabel" is-ab-solute>

<div class="-ml-[22.5px] my-4" :class="$route.path === item?.route  ? 'bg-[#fff0ea] p-3  rounded-lg' :'hover:bg-[#f2f2f2] rounded-lg p-3  hover:text-[#696F8C]'">

  <slot></slot>
</div>
        </TooltipComponent>

        <div v-else>
          <slot></slot>
        </div>
        <span class="
            text-base
            sm:hidden
            md:hidden
            lg:block
            xl:block
            whitespace-nowrap
          " :class="$route.path === item?.route || $route.path.includes(item?.name as string) ? 'O400' : ''" v-if="sideBar.showAll || showLabel">{{ item?.label
          }}</span>
      </div>
    </RouterLink>
  </div>
</template>

<script setup lang="ts">
import { PropType } from "vue";
import { useSideBar } from "@/store";
import TooltipComponent from "@/ui-kit/Tooltip/TooltipComponent.vue";

const sideBar = useSideBar()
defineProps({
  item: {
    type: Object as PropType<{ label: string; route?: string, name:string }>
  },
  isRoute: {
    type: Boolean
  },
  showLabel: {
    type: Boolean
  },

});

</script>
