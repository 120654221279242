import { Decrypt, Encrypt } from "@/services/crypto";
import { defineStore } from "pinia";

export const useAccessStore = defineStore({
  id: "accessLevel",
  state: () => ({
    activeAccessLevel: {
      id: 0 as number,
      name: "" as string,
      description: "" as string
    },
    assigned_employees: [] as Array<number>,
    accessLevelEditState: false as boolean,
    permissions: {
      dashboard: {
        selected: 0,
        enable_all: false
      },
      client_manager: {
        selected: 0,
        enable_all: false,
        view_client_manager: false,
        manage_client_manager: false,
      },
      skill_manager: {
        selected: 0,
        enable_all: false,
        view_skills_manager: false,
        manage_skills_manager: false
      },
      project_manager: {
        selected: 0,
        enable_all: false,
        view_project_manager: false,
        manage_project_manager: false,
        can_create_project: false
      },
      staffing_tracker: {
        selected: 0,
        enable_all: false,
        manage_staff_tracker: false,
        view_staff_tracker:false
      },
      settings: {
        selected: 0,
        enable_all: false,
        view_settings: false,
        manage_settings: false,
      },
    }
  }),
  getters: {},
  actions: {
    // update the store fields ready for editing
    updateAccessStore(
      basicInfo: { id: number; name: string; description: string },
      assignedEmployees: Array<number>
    ) {
      // update the active access level object
      this.activeAccessLevel.id = basicInfo.id;
      this.activeAccessLevel.name = basicInfo.name;
      this.activeAccessLevel.description = basicInfo.description;
      // update the assigned employees array
      this.assigned_employees = assignedEmployees;
      // update the access level edit state to true since its in the edit state
      this.accessLevelEditState = true;
    }
  },
  persist: {
    serializer: {
      deserialize: (value) => JSON.parse(Decrypt(JSON.parse(value))),
      serialize: (value) => JSON.stringify(Encrypt(JSON.stringify(value)))
    }
  }
});
