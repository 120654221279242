<template>
  <svg
    width="14"
    height="9"
    viewBox="0 0 14 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.6806 6.19947L10.4706 2.98947L8.51056 1.01947C7.68056 0.189473 6.33056 0.189473 5.50056 1.01947L0.32056 6.19947C-0.35944 6.87947 0.13056 8.03947 1.08056 8.03947H6.69056H12.9206C13.8806 8.03947 14.3606 6.87947 13.6806 6.19947Z"
      fill="#101840"
    />
  </svg>
</template>
