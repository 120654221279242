<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref } from "vue";
import { useStore } from "@/store/index";
import ProfileImageComponentVue from "@/ui-kit/components/Avatar/ProfileImageComponent.vue";
import { nameToInitials } from "@/apps/resource-manager/helpers/stringOperations";
import HelpSvg from "@/assets/HelpSvg.vue";

defineProps({
  windowWidth: {
    type: Number,
    default: 0
  }
})
const store = useStore();
const userName = computed(() => store.user.first_name);

const profilePicture = computed(() => store.user.profile_image);
const full_name = computed(() => (store?.user?.first_name + " " + store?.user.last_name));
const first_name = computed(() => (store?.user?.first_name));
const last_name = computed(() => (store?.user?.last_name));



const emit = defineEmits(["toggleApps", "toggleProfile", "removeDropdowns"]);


const containerRef = ref<HTMLElement | null>(null);

const closeDropDown = (event: Event) => {
  if (!containerRef.value?.contains(event.target as HTMLElement | null)) {
    emit("removeDropdowns")
  }
};

onMounted(() => {
  document.addEventListener("click", (event) => {
    closeDropDown(event);
  });
});

onUnmounted(() => {
  document.removeEventListener("click", (event) => {
    closeDropDown(event);
  });
});

</script>

<template>
  <div class="
      N700
      flex
      content-center
      items-center
      mb-1
      pb-2
      pt-5
      pr-10
      bg-[#EDEFF5]
      fixed
      top-0
      left-0
      w-full
    " :class="windowWidth > 375 ? 'justify-end' : 'justify-between'">
    <div v-if="windowWidth < 375">
      <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="40" height="40" rx="8" fill="#F4F6FA" />
        <rect x="13" y="15" width="14" height="2" rx="1" fill="#DD5928" />
        <rect x="13" y="19" width="14" height="2" rx="1" fill="#DD5928" />
        <rect x="13" y="23" width="14" height="2" rx="1" fill="#DD5928" />
      </svg>

    </div>
    <div class="flex gap-4 items-center" ref="containerRef">
      <div class="self-center cursor-pointer flex items-center gap-2 bg-[#C1C4D6] p-2 rounded-lg"
        @click="$emit('toggleApps')" v-if="windowWidth > 375">
        <img src="../assets/menu-icons/apps.svg" class="appsIcon" alt="apps-icon" />
        <p class="N900 text-base">Apps</p>
      </div>
      <div v-if="windowWidth > 375">
        <RouterLink to="/help/resource-manager/">

          <HelpSvg  @help="$emit('removeDropdowns')" title="Help"/>
        </RouterLink>
      </div>
      <div class="flex gap-4 cursor-pointer" @click="$emit('toggleProfile')">
        <img v-if="profilePicture" :src="profilePicture" class="w-10 h-10 rounded-full self-center profile-img"
          alt="profile-picture" />
        <ProfileImageComponentVue :initials="nameToInitials(full_name)" v-else-if="first_name !== undefined || last_name !== undefined" />
        <p class="self-center N800 username">{{ userName }}</p>
        <img src="@/assets/arrow-down.svg" class="cursor-pointer h-fit self-center" alt="arrow-icon" />
      </div>

    </div>
  </div>
</template>

<style>
@media (max-width: 420px) {
  .profile-img {
    border-radius: 50%;
  }

  .username {
    display: none;
  }
}
</style>
